import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {Item, Label} from 'semantic-ui-react';
import Page from '../components/site/layouts/Page';
import TaxonomyPageTitle from '../components/shared/TaxonomyPageTitle';
import SEO from '../components/shared/SEO/SEO';

const TITLE = 'Hashtag Austin Texas';
const DESCRIPTION = 'Discover and Explore all that Austin Texas has to offer';

const useData = () => {
  const result = useStaticQuery(
    graphql`
      query articleTagsPage {
        ahp {
          findArticleTags {
            nodes {
              slug
              tag
              articleCount
            }
          }
        }
      }
    `,
  );

  const tags = result?.ahp?.findArticleTags?.nodes;
  return {tags};
};

const ArticleTagsPage = () => {
  const {tags} = useData();
  return (
    <Page sidebar="blog">
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        pathname="article-tags/"
        keywords={[
          'Real estate tips',
          'Are realtors worth it',
          'Move to Austin',
          'Search for Austin realtor',
          'Resources and helpful articles',
        ]}
      />
      <TaxonomyPageTitle
        leadText="Article and Video Tags"
        subtitle={DESCRIPTION}>
        {TITLE}
      </TaxonomyPageTitle>

      <Item.Group>
        {tags &&
          tags.map(({slug, tag, articleCount}) => (
            <Item
              key={slug}
              as={Link}
              to={`/article-tags/${slug}`}
              className="big-bm">
              <Item.Content verticalAlign="middle">
                <Item.Header>
                  {tag} <Label tag>{articleCount} Articles</Label>
                </Item.Header>
              </Item.Content>
            </Item>
          ))}
      </Item.Group>
    </Page>
  );
};

export default ArticleTagsPage;
